import React from 'react';
import classes from "../SelectMultiply/style.module.css";

const Select = ({defaultValue, options, onChange}) => {
    return (
        <select className={classes.select} onChange={(e) => {onChange(e)}} name="">
            <option selected className={classes} value="">{defaultValue}</option>

            {options.map((option, index) => <option key={index} value={option}>{option}</option>)}

        </select>
    );
};

export default Select;