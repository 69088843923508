import {getDataHook} from "../../hooks/getDataHook";
import {workInDBHOOK} from "../../hooks/workInDBHook";
import {add_error_work, add_success_work, set_default_work} from "../reducers/work-reducer";

export const ThunkSaveValuesBitrix = (
    id_link,
    portal,
    chooses,
    lead_id,
    type_crm,
    id_chooses,
    id_field,
    setDisable
) => {
    return async (dispatch) => {
        dispatch(set_default_work())
        setDisable(true)

        try {
            let crm_type_name

            switch(type_crm) {
                case 'CRM_LEAD': {
                    crm_type_name = 'lead'
                    break
                }

                case 'CRM_COMPANY' : {
                    crm_type_name = 'company'
                    break
                }

                case 'CRM_DEAL' : {
                    crm_type_name = 'deal'
                    break
                }
            }

            const userField_name = await getDataHook(`crm.${crm_type_name}.userfield.get`, {id: id_field})

            let data = {
                id: lead_id,
                fields: {

                }
            }

            data.fields[userField_name.result.FIELD_NAME] = id_chooses

            console.log(data)

            await getDataHook(`crm.${crm_type_name}.update`, data)

            data = {
                id_link : id_link,
                portal : portal,
                chooses : chooses,
                lead_id : lead_id,
                type_crm : type_crm
            }

            await workInDBHOOK('set_or_change_value_for_entity', data)

            dispatch(add_success_work('Поле успешно сохранено'))
        } catch(e) {
            dispatch(add_error_work('Произошла ошибка при сохранении поля'))
            console.log(e)
        }
        setDisable(false)

        setTimeout(() => {
            dispatch(set_default_work())
        }, 3000)
    }
}