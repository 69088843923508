import React from 'react';
import classes from './style.module.css'

const Alert = ({action, message}) => {

    const style = (action === true) ? {background: '#d4edda', color: '#155724', border: '1px solid #155724'} : {background: '#f8d7da', color: '#721c24', border: '1px solid #721c24'}

    return (
        <div className={classes.alert} style={style}>
            <div className={classes.alert_wrapper}>{message}</div>
        </div>
    );
};

export default Alert;