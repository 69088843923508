import React, {useContext, useEffect, useState} from "react";
import CreateField from "./CreateField";
import {useParams} from "react-router-dom";
import {workInDBHOOK} from "../../hooks/workInDBHook";
import {useSelector} from "react-redux";

const Context = React.createContext()

export const useContextCreateField = () => {
    return useContext(Context)
}

export const CreateFieldProvider = ({change}) => {

    const {id} = useParams()
    const {portal} = useSelector(state => state.createFields)

    const createFieldData = {
        title: '',
        entity_type: [],
        json_depended_field: {},
        list_bitrix_value_and_id: [],
        field_bitrix_name: [],
    }

    const getDataAboutField = async (portal) => {
        const response = await workInDBHOOK(`get_field/${id}`, {portal: portal})

        let data = {
            title: response[0].title,
            entity_type: response[0].type_crm,
            json_depended_field: response[0].json_depended_field,
            list_bitrix_value_and_id: [],
            field_bitrix_name: []
        }
        setFieldData(data)
        setTitleFields(response[0].chooses)
        setBitrixValue(response[0].list_bitrix_value_and_id)
        console.log(response[0])
        setBitrixIdField(response[0].name_field_in_bitrix)
    }

    useEffect(() => {
        if(change && portal) {
            getDataAboutField(portal).then()
        }
    }, [portal])

    const [fieldData, setFieldData] = useState(createFieldData)
    const [titleFields, setTitleFields] = useState({title1: '', title2: '', title3: ''})
    const [bitrixValue, setBitrixValue] = useState({})
    const [bitrixIdField, setBitrixIdField] = useState({})

    const changeTitle = (e) => {
        setFieldData({...fieldData, title: e.target.value})
    }

    const getParamsEntity = (e) => {
        let array = []

        for(let option of e.options) {
            if(option.selected) {
                array.push(option.value)
            }
        }

        setFieldData({...fieldData, entity_type: array})
    }

    const [chooseSelect, setChooseSelect] = useState({step1: '', step2: '', step3: ''})

    const change_step1 = (e) => {
        setChooseSelect({...chooseSelect, step1: e.target.value, step2: '', step3: ''})
    }
    const change_step2 = (e) => {
        setChooseSelect({...chooseSelect, step2: e.target.value, step3: ''})
    }
    const change_step3 = (e) => {
        setChooseSelect({...chooseSelect, step3: e.target.value})
    }

    return(
        <Context.Provider value={{
            fieldData: fieldData,
            bitrixValue: bitrixValue,
            bitrixIdField: bitrixIdField,
            chooseSelect: chooseSelect,
            titleFields: titleFields,
            changeCheck: change,
            id_field: id,

            changeTitle,
            getParamsEntity,
            change_step1,
            change_step2,
            change_step3,
            setFieldData,
            setChooseSelect,
            setTitleFields
        }}>
            <CreateField />
        </Context.Provider>
    )
}