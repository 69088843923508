import React from 'react';
import classes from './style.module.css'
import SetTitleCreateField from "../SetTitleCreateField/SetTitleCreateField";
import ChooseEntity from "../ChooseEntity/ChooseEntity";
import DependedSettings from "../DependedSettings/DependedSettings";
import ButtonSave from "../../UI/ButtonSave/ButtonSave";
import {useSelector} from "react-redux";
import Alert from "../Alert/Alert";

const CreateField = () => {

    const {error, success} = useSelector(state => state.createFields)

    return (
        <section>
            <div className="content">
                <div className={classes.createField_wrapper}>
                    <SetTitleCreateField/>
                    <ChooseEntity />
                    <DependedSettings />
                    <ButtonSave>Сохранить</ButtonSave>

                    {error && <Alert action={false} message={error}/>}
                    {success && <Alert action={true} message={success}/>}
                </div>
            </div>
        </section>
    );
};

export default CreateField;