import {workInDBHOOK} from "../../hooks/workInDBHook";
import {loading_false, loading_true} from "../reducers/createField-reducer";
import {add_error_list, default_error_list, set_list} from "../reducers/listsFields-reducers";

export const ThunkSetList = (portal) => {
    return async (dispatch) => {
        dispatch(default_error_list())
        try {
            dispatch(loading_true())
            const response = await workInDBHOOK('get_list_fields', {portal: portal})
            dispatch(set_list(response))
            dispatch(loading_false())
        }catch(e) {
            dispatch(add_error_list('Произошла ошибка при загрузке при загрузке данных'))
        }
    }
}