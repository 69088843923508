import {loading_false, loading_true} from "../reducers/createField-reducer";
import axios from "axios";

export const ThunkCheckPortal = (portal) => {
    return async (dispatch) => {

        dispatch(loading_true())

        await axios({
            url: 'https://df.21vek.it/check_portal',
            method: 'post',
            data: {
                portal: portal
            }
        })

       dispatch(loading_false())

    }
}