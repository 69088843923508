import React, {useEffect} from 'react';
import classes from './style.module.css'
import {set_entity_type} from "../../store/reducers/work-reducer";
import {useDispatch, useSelector} from "react-redux";
import {thunkSetFieldWork} from "../../store/thunk/ThunkSetFieldWork";
import WorkItem from "./WorkItem/WorkItem";
import Alert from "../Alert/Alert";
import BX24 from "bx24-api";

const Work = () => {

    const dispatch = useDispatch()

    const {crm_entity, fields_list, lead_id, error, success} = useSelector(state => state.workReducer)
    const {portal} = useSelector(state => state.createFields)

    useEffect(() => {
        const entity_type_name = document.querySelector('#crm_entity').value
        BX24.placement.info().then(res => {
            dispatch(set_entity_type(res.options.ENTITY_ID))
        })
        dispatch(set_entity_type(entity_type_name))
    }, [])

    useEffect(() => {
        {(crm_entity && portal) && dispatch(thunkSetFieldWork(portal, crm_entity))}
    }, [crm_entity, portal])

    return (
        <div className={classes.work}>
            <div className="content">
                <ul className={'mx-4'}>
                    {fields_list.map((el, index) => <WorkItem key={index + 1} object={el} crm_entity={crm_entity} />)}
                </ul>

                {error && <Alert action={false} message={error}/>}
                {success && <Alert action={true} message={success} /> }
            </div>
        </div>
    );
};

export default Work;