import {loading_false, loading_true, set_portal} from "../reducers/createField-reducer";
import BX24 from 'bx24-api'

export const ThunkSetPortal = () => {
    return async (dispatch) => {
        dispatch(loading_true())

        const portal = await BX24.getDomain() /*"21vek.group.net"*/

        dispatch(set_portal(portal))
        dispatch(loading_false())
    }
}