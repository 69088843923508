import {
    add_error_create,
    add_success_create,
    default_error_create, default_success_create,
    loading_false,
    loading_true
} from "../reducers/createField-reducer";
import {getDataHook} from "../../hooks/getDataHook";
import {workInDBHOOK} from "../../hooks/workInDBHook";

export const ThunkUpdateDependedField = (
    idFieldBitrix,
    newValueForBitrix,
    portal,
    fieldData,
    id,
    redirect,
    titleFields
) => {
    return async (dispatch) => {

        dispatch(loading_true())

        try {
            let data = (id) => {
                return {
                    id: id,
                    fields: {
                        LIST: newValueForBitrix,
                        EDIT_FORM_LABEL: fieldData.title,
                        LIST_COLUMN_LABEL: fieldData.title,
                        LIST_FILTER_LABEL: fieldData.title
                    }
                }
            }

            await getDataHook('crm.lead.userfield.update', data(idFieldBitrix.CRM_LEAD))

            await getDataHook('crm.deal.userfield.update', data(idFieldBitrix.CRM_DEAL))

            await getDataHook('crm.company.userfield.update', data(idFieldBitrix.CRM_COMPANY))

            const valueBitrixLead = await getDataHook('crm.lead.userfield.get', {id: idFieldBitrix.CRM_LEAD})
            const valueBitrixDeal = await getDataHook('crm.deal.userfield.get', {id: idFieldBitrix.CRM_DEAL})
            const valueBitrixCompany = await getDataHook('crm.company.userfield.get', {id: idFieldBitrix.CRM_COMPANY})

            const LIST = {
                CRM_LEAD: valueBitrixLead.result.LIST,
                CRM_DEAL: valueBitrixDeal.result.LIST,
                CRM_COMPANY: valueBitrixCompany.result.LIST
            }

            const data_field = {
                portal: portal,
                json_depended_field: fieldData.json_depended_field,
                type_crm: fieldData.entity_type,
                list_bitrix_value_and_id: LIST,
                id: id,
                title: fieldData.title,
                chooses: titleFields
            }

            await workInDBHOOK('change_depended_field', data_field, 'put')

            dispatch(add_success_create('Поле успешно обновлено'))

            setTimeout(() => {
                dispatch(default_success_create())
                redirect('/settings/lists')
            }, 4000)
        } catch (e) {
            dispatch(add_error_create('Возникла ошибка при обновлении поля'))
            console.log(e)

            setTimeout(() => {
                dispatch(default_error_create())
            }, 4000)
        }

        dispatch(loading_false())
    }
}