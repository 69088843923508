import React from 'react';
import classes from './style.module.css'

const Information = () => {
    return (
        <div className={classes.information}>
            <div className="content">
                <div className={classes.information_wrapper}>
                    <div className={classes.wrapper_title}>Полезные ссылки </div>
                    <ul className={classes.wrapper_lists}>
                        <li><a target={'_blank'} href="https://b24-adx3sk.bitrix24.site">Письменная инструкция</a></li>
                        <li><a target={'_blank'} href="https://youtu.be/aI7o_maNqBk">Видео обучение</a></li>
                    </ul>

                    <div className={classes.wrapper_title}>Контакты</div>
                    <ul className={classes.wrapper_contacts}>
                        <li>Телефон: +7(385) 299-20-21 </li>
                        <li>E-mail: sales@21vek.it</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Information;