
const SET_LIST = 'SET_LIST'
const LOADING_TRUE = 'LOADING_TRUE'
const LOADING_FALSE = 'LOADING_FALSE'
const DELETE_FIELD = 'DELETE_FIELD'
const ADD_ERROR = 'ADD_ERROR'
const DEFAULT_ERROR = 'DEFAULT_ERROR'
const ADD_ERROR_ITEM = 'ADD_ERROR'
const DEFAULT_ERROR_ITEM = 'DEFAULT_ERROR'

const initialState = {
    lists: [],
    loading: false,
    error: '',
    error_item: ''
}

export const listsFieldsReducers = (state = initialState, action) => {
    switch (action.type) {

        case SET_LIST : {
            return {...state, lists: action.payload}
        }

        case LOADING_TRUE:
            return {...state, loading: true}

        case LOADING_FALSE:
            return {...state, loading: false}

        case DELETE_FIELD : {
            return {...state, lists: [...state.lists.filter(list => list.id !== action.payload)]}
        }

        case DEFAULT_ERROR:
            return {...state, error: ''}

        case ADD_ERROR:
            return {...state, error: action.payload}

        case DEFAULT_ERROR_ITEM:
            return {...state, error_item: ''}

        case ADD_ERROR_ITEM:
            return {...state, error_item: action.payload}

        default:
            return state
    }
}

export const set_list = (list) => ({type: SET_LIST, payload: list})
export const delete_field = (id) => ({type: DELETE_FIELD, payload: id})
export const add_error_list = (error) => ({type: ADD_ERROR, payload: error})
export const default_error_list = () => ({type: DEFAULT_ERROR})
export const add_error_item_list = (error) => ({type: ADD_ERROR_ITEM, payload: error})
export const default_error_item_list = () => ({type: DEFAULT_ERROR_ITEM})