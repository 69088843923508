import Header from "./components/Header/Header";
import {Routes} from 'react-router'
import './index.css'
import {Route} from "react-router-dom";
import {CreateFieldProvider} from "./components/CreateField/contextCreateField";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ThunkCheckPortal} from "./store/thunk/ThunkCheckPortal";
import {ThunkSetPortal} from "./store/thunk/ThunkSetPortal";
import FieldsList from "./components/ListFields/FieldsList";
import Work from "./components/Work/Work";
import InstallApplication from "./components/InstallApplication/InstallApplication";
import Information from "./components/Information/Information";
import {getDataHook} from "./hooks/getDataHook";
import './taiwind.css'

function App() {

    const dispatch = useDispatch()
    const {portal} = useSelector(state => state.createFields)
    const [checkInstall, setCheckInstall] = useState(null)

    const checkInstalls = async () => {
        const res = await getDataHook('userfieldtype.list')

        for(let userfield of res.result) {
            if(userfield.USER_TYPE_ID === 'depend_fields21') {
                setCheckInstall(true)
                return
            }
        }

        setCheckInstall(false)
    }

    useEffect(() => {
        console.log(setCheckInstall())
        if(checkInstall === false) {
            let data =  {
                USER_TYPE_ID: 'depend_fields21',
                HANDLER: 'https://fields.21vek.it/work',
                TITLE: 'Зависимые поля.',
                DESCRIPTION: 'testing',
                OPTIONS: {
                    height: 340
                }
            }

            const res = getDataHook('userfieldtype.add', data)
        }
    }, [checkInstall])

    useEffect((() => {
        dispatch(ThunkSetPortal())
        checkInstalls()
    }), [])

    useEffect(() => {
        portal && dispatch(ThunkCheckPortal(portal))
    }, [portal])

    return (
        <div className="App">
            <Routes>

                <Route path='/settings' element={<Header />}>
                    <Route path={''} element={<Information />}/>
                    <Route index path='create' element={<CreateFieldProvider change={false}/>}/>
                    <Route path='lists' element={<FieldsList/>}/>
                    <Route path='change/:id' element={<CreateFieldProvider change={true}/>}/>
                </Route>

                <Route path={'/work'} element={<Work/>}/>
                <Route path={'/install'} element={<InstallApplication />} />

            </Routes>
        </div>
    );
}

export default App;
