import React from 'react';
import classes from './header.module.css'
import {Link, NavLink, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import Preloader from "../Preloader/Preloader";
import './style.css'

const Header = () => {

    const loading_create = useSelector(state => state.createFields.loading)
    const loading_list = useSelector(state => state.listsFieldsReducers.loading)

    return (
        <>
            <header>
                <div className="content">
                    <div className={classes.header_wrapper}>
                        <div className={classes.wrapper_logo}>
                            <img src="/image/logo.png" alt=""/>
                        </div>

                        <div className={classes.wrapper_list}>
                            <Link className={'text-white'} to='/settings'>Главная</Link>
                            <NavLink className={'text-white'} to='/settings/create'>Создать зависимое поле</NavLink>
                            <NavLink className={'text-white'} to='/settings/lists'>Список зависимых полей</NavLink>
                        </div>
                    </div>
                </div>
            </header>

            {(loading_list || loading_create) && <Preloader/>}

            <Outlet/>
        </>
    );
};

export default Header;