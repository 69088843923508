import React from 'react';
import classes from "../Header/header.module.css";

const Preloader = () => {
    return (
        <div className={classes.preloader}>
            <div className={classes.preloader_wrapper}>
                <img src="/image/preloader.gif" alt=""/>
                <div className={classes.text}>Загрузка...</div>
            </div>
        </div>
    );
};

export default Preloader;