import React, {useEffect, useState} from 'react';
import style from './style.module.css'
import Select from "../../UI/Select/Select";
import CreateInput from "../../UI/CreateInput/CreateInput";
import {useContextCreateField} from "../CreateField/contextCreateField";
import ModalAddField from "../ModalAddField/ModalAddField";

const DependedSettings = () => {

    const {change_step1, change_step2, change_step3, fieldData, chooseSelect, setFieldData, setChooseSelect,titleFields, setTitleFields} = useContextCreateField()

    const [modal, setModal] = useState({active: false, id: ''})

    const openModal = (id) => {
        setModal({id: id, active: true})
    }

    const closeModal = () => {
        setModal({id: '', active: false})
    }

    const delete_field = (count) => {
        switch (count) {
            case 1: {
                let object = {...fieldData.json_depended_field}
                if(chooseSelect.step1 === '') {
                    break
                }
                delete object[chooseSelect.step1]
                setFieldData({...fieldData, json_depended_field: object})
                break
            }

            case 2: {
                let object = {...fieldData.json_depended_field}
                if(chooseSelect.step1 === '' && chooseSelect.step2 === '') {
                    break
                }
                delete object[chooseSelect.step1][chooseSelect.step2]
                setFieldData({...fieldData, json_depended_field: object})
                break
            }

            case 3: {
                let object = {...fieldData.json_depended_field}
                if(chooseSelect.step1 === '' && chooseSelect.step2 === '' && chooseSelect.step3 === '') {
                    break
                }
                delete object[chooseSelect.step1][chooseSelect.step2][chooseSelect.step3]
                setFieldData({...fieldData, json_depended_field: object})
                break
            }
        }
    }

    return (
        <div className={style.DependedSettings}>
            <div className={style.title}>Создание зависимого поля</div>

            {modal.active && <ModalAddField close={closeModal} id={modal.id} nesting={modal.id} />}

            <div className={style.functions}>
                <div className={style.fieldSetting}>

                    <CreateInput
                        placeholder='Название для первого уровня поля'
                        value={titleFields.title1}
                        onChange={(e) => {setTitleFields({...titleFields, title1: e.target.value})}}
                    />
                    <Select
                        defaultValue='1 уровень'
                        options={Object.keys(fieldData.json_depended_field).sort()}
                        onChange={change_step1}
                        value={chooseSelect.step1}
                    />
                    <div className={style.buttons}>
                        <div onClick={() => openModal(1)}>Добавить</div>
                        <div onClick={() => {delete_field(1)}}>Удалить</div>
                    </div>
                </div>

                <div className={style.fieldSetting}>
                    <CreateInput
                        placeholder='Название для второго уровня поля'
                        value={titleFields.title2}
                        onChange={(e) => {setTitleFields({...titleFields, title2: e.target.value})}}
                    />
                    <Select
                        defaultValue='2 уровень'
                        options={(chooseSelect.step1 !== '' && fieldData.json_depended_field[chooseSelect.step1] !== undefined) ? Object.keys(fieldData.json_depended_field[chooseSelect.step1]).sort() : []}
                        onChange={change_step2}
                        value={chooseSelect.step2}
                    />
                    <div className={style.buttons}>
                        <div onClick={() => openModal(2)}>Добавить</div>
                        <div onClick={() => {delete_field(2)}}>Удалить</div>
                    </div>
                </div>

                <div className={style.fieldSetting}>
                    <CreateInput
                        placeholder='Название для третьего уровня поля'
                        value={titleFields.title3}
                        onChange={(e) => {setTitleFields({...titleFields, title3: e.target.value})}}
                    />
                    <Select
                        defaultValue='3 уровень'
                        options={(chooseSelect.step1 !== '' && chooseSelect.step2 !== '' && fieldData.json_depended_field[chooseSelect.step1] !== undefined && fieldData.json_depended_field[chooseSelect.step1][chooseSelect.step2] !== undefined) ? Object.keys(fieldData.json_depended_field[chooseSelect.step1][chooseSelect.step2]).sort() : []}
                        onChange={change_step3}
                        value={chooseSelect.step3}
                    />
                    <div className={style.buttons}>
                        <div onClick={() => openModal(3)}>Добавить</div>
                        <div onClick={() => {delete_field(3)}}>Удалить</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DependedSettings;