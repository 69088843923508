import BX24 from 'bx24-api'
import axios from "axios";

export const getDataHook = async (method, data) => {
    const response =  await BX24.callMethod(method, data)

    return response.answer
}

/*
const baseUrl = 'https://21vekgroup.bitrix24.ru/rest/134/txfvk8ga3to1e5b7/'

export const getDataHook = async (method, data) => {
    const response = await axios({
        url: `${baseUrl}/${method}`,
        method: 'post',
        data: data
    })

    return response.data
}
*/
