import React, {useEffect} from 'react';
import classes from './style.module.css'
import {useDispatch, useSelector} from "react-redux";
import {ThunkSetList} from "../../store/thunk/ThunkSetList";
import ListItem from "./ListItem/ListItem";
import {workInDBHOOK} from "../../hooks/workInDBHook";

const FieldsList = () => {

    const {portal} = useSelector(state => state.createFields)
    const dispatch = useDispatch()
    const {lists, error} = useSelector(state => state.listsFieldsReducers)

    useEffect(() => {
        {portal && dispatch(ThunkSetList(portal))}
    }, [portal])

    return (
        <div className={classes.fieldList}>
            <div className="content">
                {(lists.length === 0) ?
                    <div className={classes.title}>Зависимые поля отсутствуют</div>
                    :
                    <div className={classes.title}>Список зависимых полей</div>
                }

                {error && <div className={classes.title} style={{color: '#721c24'}}>{error}</div>}
                <ol className={classes.lists}>
                    {lists.map((list, index) => <ListItem key={index} lists_id_bitrix={list.name_field_in_bitrix} title={list.title} id_list={list.id}/>)}

                </ol>
            </div>
        </div>
    );
};

export default FieldsList;