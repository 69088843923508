import React, {useEffect, useState} from 'react';
import classes from "../style.module.css";
import change_icon from "../../../image/change_icon.png";
import trash_icon from "../../../image/icon_trash.png";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDeleteField} from "../../../store/thunk/ThunkDeleteField";
import Alert from "../../Alert/Alert";
import {Link} from "react-router-dom";

const ListItem = ({title, id_list, lists_id_bitrix}) => {

    const dispatch = useDispatch()
    const {portal} = useSelector(state => state.createFields)
    const {error_item} = useSelector(state => state.listsFieldsReducers)

    const [success, setSuccess] = useState('')

    const deleteField = (id, lists_id) => {
        dispatch(ThunkDeleteField(id, portal, setSuccess, lists_id))
    }

    return (
        <>
            <li className={classes.list}>
                <div className={classes.title_list}>{title}</div>
                <div className={classes.actions}>
                    <div >
                        <Link to={`/settings/change/${id_list}`}>
                            <img src={change_icon} alt=""/>
                        </Link>
                    </div>
                    <div onClick={() => {deleteField(id_list, lists_id_bitrix)}}>
                        <img src={trash_icon} alt=""/>
                    </div>
                </div>
            </li>
            {error_item && <Alert action={false} message={error_item}/>}
            {success && <Alert action={true} message={success}/>}
        </>
    );
};

export default ListItem;