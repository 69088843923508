import {loading_false, loading_true} from "../reducers/createField-reducer";
import {workInDBHOOK} from "../../hooks/workInDBHook";
import {add_error_item_list, default_error_item_list, delete_field} from "../reducers/listsFields-reducers";

export const ThunkDeleteField = (id, portal, setSuccess, id_delete) => {
    return async (dispatch) => {

        try {
            dispatch(default_error_item_list())
            dispatch(loading_true())

            await workInDBHOOK(`delete_depended_field/${id}`, {portal: portal}, 'delete')
            dispatch(delete_field(id))
            dispatch(loading_false())


            setSuccess('Зависимое поле успешно удалено')

            setTimeout(() => {
                setSuccess('')
            }, 4000)
        } catch (e) {
            dispatch(add_error_item_list('Произошла ошибка при удалении элемента'))
        }
    }
}