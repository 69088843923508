import {loading_false, loading_true} from "../reducers/createField-reducer";
import {workInDBHOOK} from "../../hooks/workInDBHook";
import {set_fields, set_lead_id} from "../reducers/work-reducer";
import BX24 from 'bx24-api'

export const thunkSetFieldWork = (portal, crm_entity) => {
    return async (dispatch) => {
        dispatch(loading_true())

        const lead_id = await BX24.placement.info();

        const data = {
            portal: portal,
            type: crm_entity
        }

        const response = await workInDBHOOK('get_fields_list_type_crm', data)

        dispatch(set_fields(response))
        dispatch(set_lead_id(lead_id.options.ENTITY_VALUE_ID))

        dispatch(loading_false())
    }
}