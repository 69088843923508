import axios from "axios";

const baseURL = 'https://df.21vek.it'

export const workInDBHOOK = async (method, data, method_ = 'post') => {
    const response = await axios({
        url: `${baseURL}/${method}`,
        data: data,
        method: method_
    })

    return response.data
}