const LOADING_FALSE = 'LOADING_FALSE'
const LOADING_TRUE = 'LOADING_TRUE'
const SET_PORTAL = 'SET_PORTAL'
const RESET_DATA_TRUE = 'RESET_DATA_TRUE'
const RESET_DATA_FALSE = 'RESET_DATA_FALSE'
const ADD_ERROR = 'ADD_ERROR'
const DEFAULT_ERROR = 'DEFAULT_ERROR'
const ADD_SUCCESS = 'ADD_SUCCESS'
const DEFAULT_SUCCESS = 'DEFAULT_SUCCESS'

const initialState = {
    portal: '',
    loading: false,
    reset_data: false,
    error: '',
    success: ''
}

export const createField_reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_FALSE :
            return {...state, loading: false}

        case LOADING_TRUE :
            return {...state, loading: true}

        case SET_PORTAL:
            return {...state, portal: action.payload}

        case RESET_DATA_TRUE:
            return {...state, reset_data: true}

        case RESET_DATA_FALSE:
            return {...state, reset_data: false}

        case ADD_ERROR:
            return {...state, error: action.payload}

        case DEFAULT_ERROR:
            return {...state, error: ''}

        case ADD_SUCCESS:
            return {...state, success: action.payload}

        case DEFAULT_SUCCESS:
            return {...state, success: ''}

        default :
            return state
    }
}

export const loading_true = () => ({type: LOADING_TRUE})
export const loading_false = () => ({type: LOADING_FALSE})
export const set_portal = (portal) => ({type: SET_PORTAL, payload: portal})
export const reset_data = () => ({type: RESET_DATA_TRUE})
export const reset_default_data = () => ({type: RESET_DATA_FALSE})

export const add_error_create = (error) => ({type: ADD_ERROR, payload: error})
export const default_error_create = () => ({type: DEFAULT_ERROR})

export const add_success_create = (success) => ({type: ADD_SUCCESS, payload: success})
export const default_success_create = () => ({type: DEFAULT_SUCCESS})