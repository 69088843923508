import React from 'react';
import classes from "../CreateField/style.module.css";
import CreateInput from "../../UI/CreateInput/CreateInput";
import {useContextCreateField} from "../CreateField/contextCreateField";

const SetTitleCreateField = ({changeTitleField, createTitle}) => {

    const {changeTitle, fieldData} = useContextCreateField()

    return (
        <div className={classes.wrapper_setName}>
            <div className={classes.title}>Название зависимого поля</div>

            <div className={classes.setName_input}>
                <CreateInput
                    placeholder='Название'
                    onChange={changeTitle}
                    value={fieldData.title}
                />
            </div>
        </div>
    );
};

export default SetTitleCreateField;