import {getDataHook} from "../../hooks/getDataHook";
import {objectKeys} from "../../hooks/getValuesArray";
import {loading_false, loading_true, reset_data, reset_default_data} from "../reducers/createField-reducer";
import {workInDBHOOK} from "../../hooks/workInDBHook";

export const ThunkSaveDependedField = ({entity_type, json_depended_field, list_bitrix_value_and_id, title}, portal, chooses) => {

    return async (dispatch) => {

        dispatch(loading_true())

        let list_bitrix_value_and_id = {
            CRM_COMPANY: null,
            CRM_DEAL: null,
            CRM_LEAD: null
        }

        let name_field_in_bitrix = {
            CRM_COMPANY: null,
            CRM_DEAL: null,
            CRM_LEAD: null
        }

        const createList = {
            fields:
                {
                    "FIELD_NAME": `${Date.now()}`.substr(-15),
                    "EDIT_FORM_LABEL": title,
                    "LIST_COLUMN_LABEL": title,
                    "LIST_FILTER_LABEL" : title,
                    "USER_TYPE_ID": "enumeration",
                    "SHOW_FILTER" : "Y",
                    "LIST": objectKeys(json_depended_field).map((value) => ({VALUE: value})),
                    "SETTINGS": { "LIST_HEIGHT": 3 },
                    "MULTIPLE": "Y"
                }
        }

        const response_lead = await getDataHook('crm.lead.userfield.add', createList)
        const response_deal = await getDataHook('crm.deal.userfield.add', createList)
        const response_company = await getDataHook('crm.company.userfield.add', createList)

        name_field_in_bitrix.CRM_LEAD = response_lead.result
        name_field_in_bitrix.CRM_DEAL = response_deal.result
        name_field_in_bitrix.CRM_COMPANY = response_company.result

        console.log(name_field_in_bitrix)

        const response_list_lead =  await getDataHook('crm.lead.userfield.get', {id: name_field_in_bitrix.CRM_LEAD})

        const response_list_deal =  await getDataHook('crm.deal.userfield.get', {id: name_field_in_bitrix.CRM_DEAL})

        const response_list_company =  await getDataHook('crm.company.userfield.get', {id: name_field_in_bitrix.CRM_COMPANY})

        list_bitrix_value_and_id.CRM_LEAD = response_list_lead.result.LIST
        list_bitrix_value_and_id.CRM_DEAL = response_list_deal.result.LIST
        list_bitrix_value_and_id.CRM_COMPANY = response_list_company.result.LIST

        const data_object = {
            portal: portal,
            json_depended_field: json_depended_field,
            type_crm: entity_type,
            name_field_in_bitrix: name_field_in_bitrix,
            list_bitrix_value_and_id: list_bitrix_value_and_id,
            title: title,
            chooses: chooses
        }

        await workInDBHOOK('create_field', data_object)

        dispatch(loading_false())

        dispatch(reset_data())

        setTimeout(() => {
            dispatch(reset_default_data())
        }, 3000)
    }
}