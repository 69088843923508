import React, {useEffect, useState} from 'react';
import style from './style.module.css'
import CreateInput from "../../UI/CreateInput/CreateInput";
import {useContextCreateField} from "../CreateField/contextCreateField";

const ModalAddField = ({id, close, nesting}) => {

    const {setFieldData, fieldData, chooseSelect} = useContextCreateField()

    const [value, setValue] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {

    }, [])

    const addField = (value) => {

        error && setError('')

        if(!value || value.length < 3) {
            setError('Введите название зависимости (Больше 2 символов)')
            return
        }

        switch (nesting) {
            case 1 : {
                const object = {...fieldData.json_depended_field}
                object[value] = {}
                setFieldData({...fieldData, json_depended_field: object})
                break
            }
            case 2 : {
                if(chooseSelect.step1 == '') {
                    setError('Выберите 1 уровень поля')
                    return
                }
                const object = {...fieldData.json_depended_field}
                object[chooseSelect.step1][value] = {}
                setFieldData({...fieldData, json_depended_field: object})
                break
            }

            case 3 : {

                if(chooseSelect.step1 === '') {
                    setError('Выберите 1 уровень поля')
                    return
                }

                if(chooseSelect.step2 === '') {
                    setError('Выберите 2 уровень поля')
                    return
                }

                const object = {...fieldData.json_depended_field}
                object[chooseSelect.step1][chooseSelect.step2][value] = {}
                setFieldData({...fieldData, json_depended_field: object})
                break
            }
        }
    }

    const addValueInJSON = () => {
        addField(value)
        setValue('')
    }

    const add_field_key_enter = (e) => {
        if(e.key === 'Enter') {
            addValueInJSON()
        }
    }

    return (
        <div className={style.modal_add_field}>
            <div className={style.modal_add_field__wrapper}>
                <div className={style.title}>{id} уровень поля</div>
                {error && <div className={style.error}>{error}</div>}
                <CreateInput onKeyUp={(e) => {add_field_key_enter(e)}} value={value} onChange={(e) => {setValue(e.target.value)}} placeholder={'Название зависимости'} />
                <div className={style.buttons}>
                    <button onClick={() => {addValueInJSON()}}>Добавить</button>
                    <button onClick={close}>Закрыть</button>
                </div>
            </div>
        </div>
    );
};

export default ModalAddField;