const SET_FIELDS = 'SET_FIELDS'
const SET_ENTITY_TYPE = 'SET_ENTITY_TYPE'
const SET_LEAD = 'SET_LEAD'
const ADD_ERROR = 'ADD_ERROR'
const ADD_SUCCESS = 'ADD_SUCCESS'
const DEFAULT_ERROR_AND_SUCCESS = 'DEFAULT_ERROR_AND_SUCCESS'

const initialState = {
    crm_entity: '',
    fields_list: [],
    lead_id: null,
    error: '',
    success: '',
}

export const work_reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FIELDS :
            return {...state, fields_list: action.payload}

        case SET_ENTITY_TYPE:
            return {...state, crm_entity: action.payload}

        case SET_LEAD:
            return {...state, lead_id: action.payload}

        case ADD_ERROR:
            return {...state, error: action.payload}

        case ADD_SUCCESS:
            return {...state, success: action.payload}

        case DEFAULT_ERROR_AND_SUCCESS:
            return {...state, error: '', success: ''}

        default :
            return state
    }
}

export const set_fields = (fields_list) => ({type: SET_FIELDS, payload: fields_list})
export const set_entity_type = (entity_type) => ({type: SET_ENTITY_TYPE, payload: entity_type})
export const set_lead_id = (lead_id) => ({type: SET_LEAD, payload: lead_id})
export const add_error_work = (error) => ({type: ADD_ERROR, payload: error})
export const add_success_work = (success) => ({type: ADD_SUCCESS, payload: success})
export const set_default_work = () => ({type: DEFAULT_ERROR_AND_SUCCESS})
