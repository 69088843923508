import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import {createField_reducer} from "./reducers/createField-reducer";
import {listsFieldsReducers} from "./reducers/listsFields-reducers";
import {work_reducer} from "./reducers/work-reducer";

const rootReducers = combineReducers({
    createFields: createField_reducer,
    listsFieldsReducers: listsFieldsReducers,
    workReducer: work_reducer
})

export const store = createStore(rootReducers, composeWithDevTools(applyMiddleware(thunk)))