import React, {useEffect, useState} from 'react';
import style from './style.module.css'
import {useContextCreateField} from "../../components/CreateField/contextCreateField";
import {useDispatch, useSelector} from "react-redux";
import {ThunkSaveDependedField} from "../../store/thunk/ThunkSaveDependedField";
import Alert from "../../components/Alert/Alert";
import {objectKeys} from "../../hooks/getValuesArray";
import {ThunkUpdateDependedField} from "../../store/thunk/ThunkUpdateDependedField";
import {useNavigate} from "react-router-dom";

const ButtonSave = ({children, onClick}) => {

    const [error, setError] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {portal, reset_data} = useSelector(state => state.createFields)
    const {
        fieldData,
        titleFields,
        changeCheck,
        setFieldData,
        setTitleFields,
        bitrixValue,
        bitrixIdField,
        id_field
    } = useContextCreateField()

    const viewError = (error) => {
        setError(error)

        setTimeout(() => {
            setError('')
        }, 3000)
    }

    const save_depended_field = () => {

        if(!fieldData.title || fieldData.title.length < 2) {
            viewError('Введите название зависимого поля (Название должно быть больше 2 символов)')
            return;
        }

        if(fieldData.entity_type.length === 0) {
            viewError('выберите хотя бы одну сущьность')
            return
        }

        if(!titleFields.title1 || titleFields.title1.length < 2) {
            viewError('Введите название для первого уровня поля (Название должно быть больше 2 символов)')
            return
        }

        if(!titleFields.title2 || titleFields.title2.length < 2) {
            viewError('Введите название для второго уровня поля (Название должно быть больше 2 символов')
            return
        }

        if(!titleFields.title3) {
            viewError('Введите название для третьего уровня поля')
            return
        }

        if(Object.keys(fieldData.json_depended_field).length < 2) {
            viewError('Создайте хотя бы 2 значения для зависимого поля')
            return
        }

        if(changeCheck) {
            const newArray = objectKeys(fieldData.json_depended_field)
            const oldArray = bitrixValue.CRM_LEAD.map(value => value.VALUE)

            let newValueArray = newArray.filter(value => oldArray.indexOf(value) === -1 )

            newValueArray = newValueArray.map(value => ({VALUE: value}))

            dispatch(ThunkUpdateDependedField(bitrixIdField, newValueArray, portal, fieldData, id_field, navigate, titleFields))
        } else {
            dispatch(ThunkSaveDependedField(fieldData, portal, titleFields))
        }

    }

    useEffect(() => {
        if(reset_data) {
            setFieldData({
                title: '',
                entity_type: [],
                json_depended_field: {},
                list_bitrix_value_and_id: [],
                field_bitrix_name: [],
            })
            setTitleFields({title1: '', title2: '', title3: ''})
        }
    }, [reset_data])

    return (
        <>
            <button onClick={(e) => {save_depended_field()}} className={'m-auto my-8 bg-amber-300 px-6 py-2 text-2xl text-black'}>{children}</button>
            {error && <Alert action={false} message={error}/>}
            {reset_data && <Alert action={true} message={'Поле успешно сохранено'} />}
        </>
    );
};

export default ButtonSave;