import React from 'react';
import SelectMultiply from "../../UI/SelectMultiply/SelectMultiply";
import classes from './style.module.css'

const ChooseEntity = () => {

    return (
        <div className={classes.chooseEntity}>
            <div className={classes.title}>Выберите сущность</div>
            <div className={classes.wrapper_select}>
                <SelectMultiply defaultValue='Сущность' options={[
                    {value: 'CRM_LEAD', title: 'Лиды'},
                    {value: 'CRM_COMPANY', title: 'Компании'},
                    {value: 'CRM_DEAL', title: 'Сделки'},
                ]} />
            </div>
        </div>
    );
};

export default ChooseEntity;