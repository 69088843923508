import React, {useEffect} from 'react';
import BX24 from 'bx24-api'

const InstallApplication = () => {

    const install = async () => {
        let data =  {
            USER_TYPE_ID: 'depend_fields21',
            HANDLER: 'https://fields.21vek.it/work',
            TITLE: 'Зависимые поля.',
            DESCRIPTION: 'testing',
            OPTIONS: {
                height: 340
            }
        }

        const response = await BX24.callMethod('userfieldtype.add', data)

        console.log(response)

        data = {
            USER_TYPE_ID: 'depend_fields21',
            FIELD_NAME: 'depend_fields21',
            XML_ID: 'depend_fields21',
            MANDATORY: 'N',
            SHOW_IN_LIST: 'Y',
            EDIT_IN_LIST: 'Y',
            EDIT_FORM_LABEL: 'Зависимые поля.',
            LIST_COLUMN_LABEL: 'Зависимые поля.',
            SETTINGS : {}
        }
        await BX24.callMethod('crm.lead.userfield.add', data)
        await BX24.callMethod('crm.deal.userfield.add', data)
        await BX24.callMethod('crm.company.userfield.add', data)
    }

    useEffect(() => {
        install().then()
    }, [])

    return (
        <div>

        </div>
    );
};

export default InstallApplication;