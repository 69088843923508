import React, {useEffect} from 'react';
import classes from './style.module.css'
import {useContextCreateField} from "../../components/CreateField/contextCreateField";

const SelectMultiply = ({defaultValue, options, onChange}) => {

    const {getParamsEntity, fieldData} = useContextCreateField()


    return (
        <select className={classes.select} multiple name="" id="" onChange={(e) => {getParamsEntity(e.target)}}>
            <option selected disabled className={classes} value="">{defaultValue}</option>
            {options.map((option, index) => (fieldData.entity_type.indexOf(option.value === -1)) ?
                <option className={classes} key={index+1} value={option.value}>{option.title}</option>
                :
                <option selected className={classes} key={index+1} value={option.value}>{option.title}</option>)}
        </select>
    );
};

export default SelectMultiply;