import React, {useEffect, useState} from 'react';
import classes from './style.module.css'
import {useDispatch, useSelector} from "react-redux";
import {ThunkSaveValuesBitrix} from "../../../store/thunk/ThunkSaveValuesBitrix";
import {workInDBHOOK} from "../../../hooks/workInDBHook";
import {objectKeys} from "../../../hooks/getValuesArray";
import SelectWork from "../../../UI/SelectWork/SelectWork";

const WorkItem = ({object}) => {

    const getKeysArray = (object) => {
        return Object.keys(object)
    }
    const choosesObject = {
        step1: '',
        step2: '',
        step3: ''
    }

    const setDefaultValueForChooses = async () => {
        const data = {
            id_link : object.id,
            portal : portal,
            lead_id : lead_id,
            type_crm : crm_entity
        }

        const response = await workInDBHOOK('get_choose_depended_field', data)

        if(response.length > 0) {
            let result_deploy = {}
            const result = response[0].chooses

            if(checkExistsChooses(result.step1) !== -1) {
                result_deploy.step1 = result.step1
            } else {
                result_deploy.step1 = ''
            }

            if(checkExistsChooses(result.step1) !== -1 && checkExistsChooses(result.step2) !== -1) {
                result_deploy.step2 = result.step2
            } else {
                result_deploy.step2 = ''
            }

            if(checkExistsChooses(result.step1) !== -1 && checkExistsChooses(result.step2) !== -1 && checkExistsChooses(result.step3) !== -1) {
                result_deploy.step3 = result.step3
            } else {
                result_deploy.step3 = ''
            }

            setChoose(result_deploy)
        }
    }

    useEffect(() => {
        setDefaultValueForChooses().then(() => {
        })
    }, [])

    const checkExistsChooses = (value) => {
        return objectKeys(object.json_depended_field).indexOf(value)
    }

    const [choose, setChoose] = useState(choosesObject)
    const {crm_entity, lead_id} = useSelector(state => state.workReducer)
    const {portal} = useSelector(state => state.createFields)
    const dispatch = useDispatch()

    const onChangeSelect = (e, step) => {
        switch (step) {
            case 1:
                setChoose({step1: e.target.value, step2: '',
                step3: ''})
                break

            case 2 :
                setChoose({...choose, step2: e.target.value, step3: ''})
                break

            case 3 :
                setChoose({...choose, step3: e.target.value})
                break
        }
    }

    const getIDChooseFields = (object) => {
        return object.reduce((values, value) => {
            if(value.VALUE === choose.step1 || value.VALUE === choose.step2 || value.VALUE === choose.step3) {
                values.push(value.ID)
            }

            return values
        }, [])
    }

    const saveFields = (id_link) => {
        const IDChooses = getIDChooseFields(object.list_bitrix_value_and_id[crm_entity])
        dispatch(ThunkSaveValuesBitrix(id_link, portal, choose, lead_id, crm_entity, IDChooses, object.name_field_in_bitrix[crm_entity], setDisable))
    }

    const [disable, setDisable] = useState(false)

    return (
        <li className={classes.item}>
            <div className={'text-gray-500 text-xl mb-4'}>{object.title}</div>

            <div>
                <SelectWork value={choose.step1} step={1} onChange={onChangeSelect} defaultValue={object.chooses?.title1}>
                    {getKeysArray(object.json_depended_field).sort().map((el, index) => <option key={index + 1} value={el}>{el}</option>)}
                </SelectWork>

                <SelectWork value={choose.step2} step={2} onChange={onChangeSelect} defaultValue={object.chooses?.title2}>
                    {
                        (choose.step1 !== '') && getKeysArray(object.json_depended_field[choose.step1]).sort().map((el, index) =>
                            <option key={index + 1} value={el}>{el}</option>)
                    }
                </SelectWork>

                <SelectWork value={choose.step3} step={3} onChange={onChangeSelect} defaultValue={object.chooses?.title3}>
                    {
                        (choose.step1 !== '' && choose.step2 !== '') && getKeysArray(object.json_depended_field[choose.step1][choose.step2]).sort().map((el, index) =>
                            <option key={index + 1} value={el}>{el}</option>)
                    }
                </SelectWork>
            </div>

            <button
                onClick={() => saveFields(object.id)}
                className={'bg-green-300 px-4 py-2 rounded-none my-4 hover:bg-green-500 hover:text-white hover:scale-100 text-md'}
                disabled={disable}
            >{disable ? 'Идет сохранение' : 'Сохранить'}</button>

        </li>
    );
};

export default WorkItem;