import React from 'react';

const SelectWork = ({children, defaultValue, onChange, step, value}) => {
    return (
        <div className={'border relative px-4 py-2 bg-white mb-8 rounded-b-sm'}>
            <div className={'absolute -top-4 bg-white text-gray-400'}>{defaultValue}</div>
            <select className={'w-full text-md'} value={value} onChange={(e) => {onChange(e, step)}}  name="" id="">
                <option className={''} selected disabled value=""></option>
                {children}
            </select>
        </div>
    );
};

export default SelectWork;